import React from 'react';
import { FaSpotify } from 'react-icons/fa';

import './index.css';

const ContactInfo = (props) => {
    let whatsapp_url = `https://wa.me/${props.company.phone.match( /\d+/g).join('')}`;  
    
    return (
      <>
        <div style={{"text-align": "center"}}>
          <address>
            <strong>CNPJ:</strong>&nbsp;14.957.619/0001-01
          </address>

          <address>
            <abbr title="Telefone"><strong>Tel:</strong></abbr>&nbsp;<a href={whatsapp_url} target="_blank" title="WhatsApp" rel="noopener noreferrer">{props.company.phone}</a>
          </address>
    
          <address>
            <strong>E-mail:</strong>&nbsp;<a href="mailto:#">{props.company.email}</a>
          </address>

          <address>
            <strong>LGPD:</strong>&nbsp;<a href="https://safetytec.freshdesk.com/support/solutions/articles/44002531639-lgpd" rel="noreferrer" target="_blank">mais informações</a>
          </address>
    
          <address>
            <ul className="company-social">
              <li className="social-instagram"><a href="https://www.instagram.com/safetytec.br" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
              <li className="social-facebook"><a href="https://fb.com/SafetyTec" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
              <li className="social-twitter"><a href="https://twitter.com/SafetyTec" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a></li>
              <li className="social-youtube"><a href="https://www.youtube.com/c/SafetyTec" target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube"></i></a></li>
              <li className="social-linkedin"><a href="https://www.linkedin.com/company/safetytec-br" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li>
              <li className="social-spotify"><a href="https://open.spotify.com/show/51R2DjcWkJ904t4v6NBrEV?si=Cw7lKjPWR6qWjF64qHGKaw" target="_blank" rel="noopener noreferrer"><FaSpotify /></a></li>
            </ul>
          </address>
        </div>
      </>
    )
  }

export default ContactInfo;